<template>
  <div :class="baseClass + '-play-page'">
    <div class="play-container">
      <iframe width="100%" height="100%" id="play-container" :src="info.url"></iframe>
    </div>
    <div :class="baseClass + '-ad-box'">
      <div :class="baseClass + '-ad-box-title'">Advertisement</div>
      <div :class="baseClass + '-ad-box-content'"></div>
    </div>
    <div :class="baseClass + '-row'">
      <div :class="
          baseClass +
          '-all-container' +
          ' ' +
          baseClass +
          '-linear-border-container'
        ">
        <div :class="
            baseClass +
            '-all-content' +
            ' ' +
            baseClass +
            '-linear-border-content'
          ">
          <img v-if="isPc" :class="baseClass + '-all-container-title'" src="@/assets/images/games-title-icon2.png" />
          <div :class="baseClass + '-all-container-list'">
            <Game v-for="item of $store.state.games.slice(0, 96)" :item="item" :key="item.id" type="all" />
          </div>
        </div>
      </div>
    </div>
    <Tips />
  </div>
</template>

<script>
import Game from "@/components/GameItem.vue";
import Tips from "@/components/Tips.vue";
export default {
  components: { Game, Tips },
  data () {
    return {
      games: [],
      info: {},
    };
  },
  watch: {
    $route: {
      handler () {
        this.init();
      },
      deep: true,
    },
  },
  mounted () {
    console.log(this.$route);
    this.init();
  },
  methods: {
    init () {
      const id = this.$route.params.id;
      if (id) {
        this.$store.dispatch("getGameInfo", {
          id,
          callback: (game) => {
            console.log(game)
            this.info = game;
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.#{$baseClass}-play-page {
  .play-container {
    width: 100%;
    height: 100vh;
  }
  .#{$baseClass}-row {
    &:nth-child(2) {
      padding-top: 76px;
      margin-top: 50px;
    }
  }
  .#{$baseClass}-container {
    &-title {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      top: -44px;
      .container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 60px;
        height: 88px;
        background: linear-gradient(180deg, #fed537 0%, #ff6b94 100%);
        box-shadow: 0px 4px 16px 0px rgba(255, 114, 142, 0.3);
        border-radius: 8px;
        font-size: 36px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #ffffff;
        line-height: 42px;
        text-shadow: 0px 4px 16px rgba(255, 114, 142, 0.3), 0px 4px 0px #db4d01;
      }
    }
  }
  .#{$baseClass}-info-container {
    width: 1360px;
    height: 480px;
    .#{$baseClass}-info-content {
      position: relative;
      background: #2b89ff;
      display: flex;
      flex-direction: column;
    }
    &-content {
      flex: 1;
      display: flex;
      align-items: center;
      padding-left: 80px;
      &-banner {
        width: 316px;
        min-width: 316px;
        height: 316px;
        border-radius: 40px;
        border: 4px solid #fedc32;
        margin-right: 42px;
      }
      &-box {
        &-label {
          font-size: 32px;
          font-family: Arial-BoldMT, Arial;
          font-weight: normal;
          color: #ffffff;
          margin-bottom: 20px;
        }
        &-text {
          font-size: 20px;
          font-family: Arial-BoldMT, Arial;
          font-weight: normal;
          color: #fff;
          margin-bottom: 9px;
        }
        &-btn {
          display: flex;
          justify-content: center;
          .btn {
            width: 270px;
            height: 94px;
            border-radius: 53px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;
            // animation: shake-bottom 1.6s cubic-bezier(0.455, 0.03, 0.515, 0.955)
            // infinite both;

            .text {
              position: relative;
              z-index: 3;
              font-size: 32px;
              font-family: Arial-BoldMT, Arial;
              font-weight: normal;
              color: #ffffff;
              line-height: 37px;
            }
            &::before,
            &::after {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              content: '';
              border-radius: 53px;
            }
            &::before {
              z-index: 1;
              background: #ee4a78;
              top: 4px;
            }
            &::after {
              z-index: 2;
              background: #fe5e8a;
            }
          }
        }
      }
    }
    .#{$baseClass}-info-title {
      height: 80px;
      background: #fedc32;
      border-radius: 18px 18px 0px 0px;
      font-size: 36px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #333333;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}
@media only screen and (min-width: 420px) and (max-width: 1300px) {
  .#{$baseClass}-play-page {
    .play-container {
      height: 100vh;
    }
  }
}
@media only screen and (max-width: 420px) {
  .#{$baseClass}-play-page {
  .play-container {
    height: 60vh;
    max-height: 700px;
  }
    .#{$baseClass}-row {
      padding: 0;
      &:nth-child(2) {
        padding-top: 44px;
      }
    }
    .kizgamer-game-item.all {
      margin-right: 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    .#{$baseClass}-all-container-list {
      padding: 10px;
      justify-content: space-between;
    }
  }
}
</style>
