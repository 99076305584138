var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.baseClass + '-play-page'},[_c('div',{staticClass:"play-container"},[_c('iframe',{attrs:{"width":"100%","height":"100%","id":"play-container","src":_vm.info.url}})]),_c('div',{class:_vm.baseClass + '-ad-box'},[_c('div',{class:_vm.baseClass + '-ad-box-title'},[_vm._v("Advertisement")]),_c('div',{class:_vm.baseClass + '-ad-box-content'})]),_c('div',{class:_vm.baseClass + '-row'},[_c('div',{class:_vm.baseClass +
        '-all-container' +
        ' ' +
        _vm.baseClass +
        '-linear-border-container'},[_c('div',{class:_vm.baseClass +
          '-all-content' +
          ' ' +
          _vm.baseClass +
          '-linear-border-content'},[(_vm.isPc)?_c('img',{class:_vm.baseClass + '-all-container-title',attrs:{"src":require("@/assets/images/games-title-icon2.png")}}):_vm._e(),_c('div',{class:_vm.baseClass + '-all-container-list'},_vm._l((_vm.$store.state.games.slice(0, 96)),function(item){return _c('Game',{key:item.id,attrs:{"item":item,"type":"all"}})}),1)])])]),_c('Tips')],1)}
var staticRenderFns = []

export { render, staticRenderFns }